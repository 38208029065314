@import '../../sass/utils.scss';

.formatos {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4; 
    overflow: scroll;
    & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    .formatos__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: left;
        padding: 30px;
    }
    & .info-card {
        margin-right: 50px;
        padding-top: 1px;
    }
}
